<template>
    <v-row>
        <lista-empreendimentos></lista-empreendimentos>
    </v-row>
</template>

<script>
import ListaEmpreendimentos from './ListaEmpreendimentos.vue'

export default {
    components: {
        ListaEmpreendimentos,
    },
    setup() {
        
    },
}
</script>