<template>
    <v-row>
      <v-col sm="12"
      cols="12">
        <h3 v-if="!this.existImoveis">Nenhum imvóvel encontado.</h3>
      </v-col>
      <v-col
        sm="6"
        cols="12"
        v-for="(item, index) in this.empreendimentos" :key="index"
      >
        <v-card class="d-flex align-center">
          <div class="col-12 d-flex justify-space-between flex-wrap flex-md-nowrap flex-column flex-md-row">
            <div class="mx-auto">
              <v-img
                width="220"
                height="100%"
                src="@/assets/images/pages/tax-ir.png"
              ></v-img>
            </div>
            <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>
            <div>
              <v-card-title>
                {{ item.identificador }}
              </v-card-title>
              <v-card-text class="col-12">
                 {{  item.desc_obra  }}
              </v-card-text>
              <v-card-text class="text--primary text-base">
                <span>{{ item.codigoObra}}</span> <span class="font-weight-bold"></span>
              </v-card-text>
              <v-card-actions class="d-flex justify-space-between dense">
                <v-btn
                  text
                  color="primary"
                  dark
                >
                  <v-row justify="center">
                  <v-dialog
                    persistent
                    :retain-focus="false"
                    max-width="600px"
                    v-model="dialogs[index]"
                  >
                  
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                        VER EXTRATO
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Selecione um <strong>ano base</strong></span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-select
                                :items="years"
                                label="Ano base*"
                                required
                                v-model="anoBase"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                        <small>* indica campo obrigatório</small>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red darken-1"
                          text
                          outlined
                          @click="closeDialogs(index)"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="light-green accent-4"
                          text
                          outlined
                          @click="gerarPDFIR(item.numeroVenda, item.codigoObra, item.codigoEmpresa)"
                        >
                          Gerar PDF
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                    <v-snackbar
                      v-model="snackbar"
                      :timeout="-1"
                    >
                      {{ toastText }}

                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="primary"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                        >
                          Fechar
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </v-dialog>
                </v-row>
                </v-btn>
                <v-btn icon>
                  <v-icon>{{ icons.mdiOfficeBuildingMarkerOutline }}</v-icon>
                </v-btn>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
import { mdiOfficeBuildingMarkerOutline } from '@mdi/js'
import Cookie from 'js-cookie'
import UsuarioStore from './../../store/Usuario'
import moment from 'moment'
import Strings from '@/common/strings'

export default {
    mounted: function(){

      this.momentF = moment

      for (let i = 1; i <= 4; i++) {
        let year = moment().subtract(i, 'years').year()
        this.years.push(year)
      }

      this.$loading(true)

      UsuarioStore.resumovenda(JSON.parse(Cookie.get('payloadEmpreendimentos')), Strings.imobiliariaId).then(result => {
        this.empreendimentos = result.data

        var dialogsShow = []

        this.empreendimentos.map((val, index) => {
          dialogsShow[index] = false
        })

        this.dialogs = dialogsShow

        if(this.empreendimentos.length == 0)
          this.existImoveis = false
        else
          this.existImoveis = true

      }).catch(error => {

        if(error.response.status == 401){
                Cookie.remove(Strings.cookie_token)
                Cookie.remove(Strings.cookie_username)
                this.$router.push('/pages/login')
            }

        console.log(error)
      }).finally(() => {
        this.$loading(false)
      })
    },
    data(){
      return{
        existImoveis: false,
        dialog: false,
        anoBase: '',
        years: [],
        toastText: '',
        snackbar: false,
        empreendimentos: Array,
        dialogs: Array
      }
    },
    methods: {
        gerarPDFIR(venda, obra, empresa){

          var payload = {
              'venda': venda,
              'obra': obra,
              'empresa': empresa,
              'ano_base': this.anoBase
            }

          this.$loading(true)
            var payload = {
              'venda': venda,
              'obra': obra,
              'empresa': empresa,
              'ano_base': this.anoBase
            }

            if(this.anoBase == '' || this.anoBase == 0){
              this.toastText = 'Selecione o "ano base" corretamente'
              this.snackbar = true
              return
            }
            
            UsuarioStore.gerarPDFIR(payload, Strings.imobiliariaId).then(result => {
                this.$router.push({name: 'pdf-viewer', params: { pdfBase64: result.data.toString()}})
            }).catch(error => {
              
              if(error.response.status == 401){
                Cookie.remove(Strings.cookie_token)
                Cookie.remove(Strings.cookie_username)
                this.$router.push('/pages/login')
              }

              this.toastText = 'Erro ao gerar extrato de IR, informe o suporte!'
              this.snackbar = true
            }).finally(() =>{
              this.$loading(false)
            })
        },
        closeDialogs(index){
          this.$set(this.dialogs, index, false)
        }
    },
    setup() {

      const momentF = Object
      
      return {
        icons:{
            mdiOfficeBuildingMarkerOutline
        },
        momentF,
      }

    },
}
</script>

<style scoped>

</style>